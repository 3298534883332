









































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import CustomModal from "@/components/CustomModal.vue";
import { IDiscount } from "@/interfaces/common";

@Component({
  components: {
    CustomModal,
  },
})
export default class Discounts extends Vue {
  @Prop({
    type: Array,
  })
  discounts!: IDiscount[];

  dialogOpen = false;
  closeDialog() {
    this.dialogOpen = false;
  }

  get thereIsLastMinute() {
    return this.discounts.find((dis) => dis.Type == 2);
  }
}
