





















/* eslint-disable no-undef */
import Vue from "vue";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { ICoordinates } from "@/interfaces/common";
import { settingStore } from "@/store/modules/setting";

@Component
export default class AccoMap extends Vue {
  @Ref("mapContainer")
  readonly mapContainer!: HTMLDivElement;
  @Prop({
    type: Object,
    default: () => ({
      Lat: 51.505,
      Lng: -0.9,
    }),
  })
  readonly coordinates!: ICoordinates;

  map: google.maps.Map | null = null;
  marker: google.maps.Marker | null = null;

  @Watch("coordinates")
  watchCoordinates(val: ICoordinates) {
    this.map?.setCenter({
      lat: val.Lat,
      lng: val.Lng,
    });
    this.marker?.setPosition({
      lat: val.Lat,
      lng: val.Lng,
    });
  }

  async mounted() {
    if (settingStore.mapKey == null) {
      console.warn(
        "[MAP]: se necesita el map key de google para inicializar el mapa"
      );
      return;
    }
    const loader = new Loader({
      apiKey: settingStore.mapKey,
      version: "weekly",
      libraries: [],
    });
    const google = await loader.load();
    this.map = new google.maps.Map(this.mapContainer, {
      center: {
        lat: this.coordinates.Lat,
        lng: this.coordinates.Lng,
      },
      zoom: 15,
      maxZoom: 17,
      streetViewControl: false,
      styles: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
    this.marker = new google.maps.Marker({
      position: {
        lat: this.coordinates.Lat,
        lng: this.coordinates.Lng,
      },
      // TODO: subir icono en el servidor
      // icon: "/img/map-marker-green.png",
      map: this.map,
    });
    this.map.addListener("tilesloaded", () => {
      this.$emit("tilesloaded", this.map);
    });
  }
}
