











































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { authStore } from "@/store/modules/auth";

import {
  IAccommodationSearch,
  IAccommodationSearchResponse,
} from "@/interfaces/accommodation";

import { irentAPI } from "@/services/api";

@Component
export default class Home extends Vue {
  get tokenAdministrator() {
    return authStore.tokenAdministrator;
  }

  get administrator() {
    return authStore.administrator;
  }

  accommodations = new Array<IAccommodationSearch>();
  loadingAccommodations = false;

  @Watch("tokenAdministrator")
  protected async watchTokenAdministrator(newVal: string) {
    if (!newVal) return;
    this.loadingAccommodations = true;
    try {
      const res = await irentAPI.post<IAccommodationSearchResponse>(
        "/v1/Accommodation/Search",
        {
          Guests: 0,
          Location: {},
          Currency: "EUR",
          Filters: {
            PriceRange: {
              Begin: null,
              End: null,
            },
          },
          WebsiteId: JSON.parse(
            authStore.payloadTokenAdministrator.agentWebsites
          )[0].WebsiteId,
        }
      );
      this.accommodations = res.data.Accommodations;
    } catch (err) {
      console.warn("[POST][ACCOMMODATION_SEARCH]: ", err);
    }
    this.loadingAccommodations = false;
  }
}
