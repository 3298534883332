
















































































































import Vue from "vue";
import { Component, Prop, VModel, Watch } from "vue-property-decorator";

import { ILocalGuests } from "@/interfaces/common";

import CustomModal from "@/components/CustomModal.vue";

@Component({
  components: {
    CustomModal,
  },
})
export default class GuestsField extends Vue {
  @VModel({ type: Object, default: () => null })
  guests!: ILocalGuests;
  @Prop({
    type: Boolean,
    default: () => false,
  })
  readonly hideDetails!: boolean;
  @Prop({
    type: Boolean,
    default: () => true,
  })
  readonly clearable!: boolean;

  @Prop({ type: Number })
  maxGuests!: number;

  @Watch("guests", { immediate: true })
  watchDates(val: ILocalGuests) {
    let guests: ILocalGuests = {
      GuestAdults: 1,
      GuestChildrens: 0,
      GuestInfants: 0,
    };
    if (!val) return guests;
    guests.GuestAdults = val.GuestAdults;
    if (!val) return guests;
    guests.GuestChildrens = val.GuestChildrens;
    if (!val) return guests;
    guests.GuestInfants = val.GuestInfants;
    return guests;
  }

  get totalGuests() {
    const result = this.guests
      ? this.guests.GuestAdults +
        this.guests.GuestChildrens +
        this.guests.GuestInfants
      : "";

    return result;
  }
  get totalGuestsString() {
    let guests =
      this.totalGuests == 1
        ? this.$tc("listResults.sidebar.guest.guest", 1)
        : this.$tc("listResults.sidebar.guest.guest", 2);
    return (
      this.totalGuests + " " + guests.charAt(0).toUpperCase() + guests.slice(1)
    );
  }

  dialog = false;

  protected addGuest(
    type: "GuestAdults" | "GuestChildrens" | "GuestInfants",
    add: number
  ) {
    if (
      add > 0 && this.totalGuests ? this.totalGuests >= this.maxGuests : false
    )
      return;
    const newVal =
      this.guests == null
        ? {
            GuestAdults: 1,
            GuestChildrens: 0,
            GuestInfants: 0,
          }
        : this.guests;

    newVal[type] += add;
    this.guests = { ...newVal };
  }
}
