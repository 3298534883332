
































































import { IExtra } from "@/interfaces/common";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ExtrasIncluded extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly flat!: boolean;
  @Prop({
    type: Array,
    required: true,
  })
  readonly extras!: Array<IExtra>;

  @Prop({
    type: Number,
    required: true,
  })
  readonly guests!: number;

  get extrasMandatoryFiltered() {
    const arr = this.extras
    .filter((extra) =>  !extra.PriceIncluded && !(extra.Amount === 0 && extra.Type !== 100 &&  extra.Type !== 14))
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Description)
          .localeCompare(
            this.$common.toLangName(b.Description),
            this.$i18n.locale
          )
      );
    return arr;
  }

  get extrasInPriceFiltered() {
    const arr = this.extras
      .filter((extra) => extra.PriceIncluded || extra.BookingIncluded)
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Description)
          .localeCompare(
            this.$common.toLangName(b.Description),
            this.$i18n.locale
          )
      );
    return arr;
  }
}
