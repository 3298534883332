import { IDates, ILocalGuests, ILocalLocation } from "@/interfaces/common";
import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";

const FAVORITES_ACCO_KEY = "list.favorites_acco";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "list",
})
export class ListStore extends VuexModule {
  pageNumber = 1;
  location: ILocalLocation | null = null;
  dates: IDates = {
    Begin: "",
    End: "",
  };
  guests: ILocalGuests | null = null;
  accommodationType: number[] | null = null;
  bedrooms: number | null = null;
  bathrooms: number | null = null;
  priceRage: [number, number] = [0, 0];
  filters: string[] = [];
  listScrollTop = 0;
  showingFavorites = localStorage.getItem("showingFavorites")
    ? JSON.parse(localStorage.getItem("showingFavorites") ?? "false")
    : false;

  favoritesAcco: number[] = localStorage.getItem(FAVORITES_ACCO_KEY)
    ? JSON.parse(localStorage.getItem(FAVORITES_ACCO_KEY) ?? "[]")
    : [];

  @Mutation
  setPage(payload: { pageNumber: number }) {
    this.pageNumber = payload.pageNumber;
  }

  @Mutation
  setScrollTop(payload: { top: number }) {
    this.listScrollTop = payload.top;
  }

  @Mutation
  saveSearchOptions(options: {
    location: ILocalLocation | null;
    dates: IDates;
    guests: ILocalGuests | null;
    totalGuests: number;
    typeOfAccommodation: number[] | null;
    bedrooms: number | null;
    bathrooms: number | null;
    priceRage: [number, number];
    filters: string[];
  }) {
    this.location = options.location;
    this.dates = options.dates;
    this.guests = options.guests;
    this.accommodationType = options.typeOfAccommodation;
    this.bedrooms = options.bedrooms;
    this.bathrooms = options.bathrooms;
    this.priceRage = options.priceRage;
    this.filters = options.filters;
  }

  @Mutation
  addOrRemoveFavoriteAcco(accoID: number) {
    const index = this.favoritesAcco.indexOf(accoID);
    if (index == -1) {
      this.favoritesAcco.push(accoID);
    } else {
      this.favoritesAcco.splice(index, 1);
    }
    localStorage.setItem(
      FAVORITES_ACCO_KEY,
      JSON.stringify(this.favoritesAcco)
    );
  }

  @Mutation
  showFavorites(show: boolean) {
    this.showingFavorites = show;
    localStorage.setItem("showingFavorites", this.showingFavorites);
  }
}

export const listStore = getModule(ListStore);
