

































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CustomModal extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  value!: boolean;

  @Prop({
    type: String,
  })
  title!: string;
  @Prop({
    type: Boolean,
  })
  fullWidth!: boolean;

  animation = false;
  @Watch("value") onChange() {
    setTimeout(() => {
      this.animation = !this.animation;
    }, 150);
  }
  closeDialog() {
    this.$emit("onClose");
  }
}
