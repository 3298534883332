








































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { irentAPI } from "@/services/api";
import { authStore } from "@/store/modules/auth";
import { listStore } from "@/store/modules/list";
import { IDates } from "@/interfaces/common";

@Component
export default class InfoBoxMap extends Vue {
  @Prop({
    type: Number,
    default: null,
  })
  accommodationId: any;

  @Prop({
    type: Object,
    default: () => ({
      Begin: null,
      End: null,
    }),
  })
  dates!: IDates;

  loading = true;
  accommodation: any;

  get areaName() {
    return this.accommodation.Address.Area.Name;
  }
  get cityName() {
    return this.accommodation.Address.City.Name;
  }
  get countryName() {
    const names = this.accommodation.Address.Country.Names;
    const keys = Object.keys(names);
    return names[keys[0]];
  }
  @Watch("accommodationId")
  async watchAccommodationId(id: any) {
    // Cada vez que se cambie el valor, consultamos la informacion del alojamiento
    this.loading = true;
    const result = await this._getInfoBoxMap({
      AccommodationId: id,
    });
    this.loading = false;
    this.accommodation = result;
  }

  private async _getInfoBoxMap(params: { AccommodationId: number }) {
    const { AccommodationId } = params;
    try {
      const res = await irentAPI.post("/v1/Accommodation/InfoBoxMap", {
        AccommodationId,
        Languages: authStore.language,
        Currency: authStore.currency,
        WebsiteId: authStore.websiteId,
        Dates: this.dates,
      });
      return res.data;
    } catch (err) {
      console.warn("[POST][GET_INFO_BOX_MAP]", err);
      return null;
    }
  }

  protected goToDetail() {
    listStore.setScrollTop({ top: window.scrollY });
    this.$common.to(authStore.sales ? "SalesAccoDetail" : "AccoDetail", {
      accommodationId: this.accommodationId,
    });
  }
}
