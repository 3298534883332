import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "setting",
})
export class SettingStore extends VuexModule {
  mapKey = "";

  @Mutation
  setMapKey(payload: string) {
    this.mapKey = payload;
  }
}

export const settingStore = getModule(SettingStore);
