import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
let themeName = document.querySelector("irent-script")?.getAttribute("theme");
themeName = themeName ? themeName : "default";
const themes = {
  lblue: {
    light: {
      primary: "#5cbbf6",
      secondary: "#1867c0",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      white: "#FFFFFF",
    },
  },
  orange: {
    light: {
      primary: "#ffbf75",
      secondary: "#1867c0",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      white: "#FFFFFF",
    },
  },
  red: {
    light: {
      primary: "#f86f6f",
      secondary: "#1867c0",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      white: "#FFFFFF",
    },
  },
  grey: {
    light: {
      primary: "#969696",
      secondary: "#1867c0",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      white: "#FFFFFF",
    },
  },
  green: {
    light: {
      primary: "#009929",
      secondary: "#1867c0",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      white: "#FFFFFF",
    },
  },
  beige: {
    light: {
      primary: "#e8c39e",
      secondary: "#1867c0",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      white: "#FFFFFF",
    },
  },
};
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      ...themes[themeName],
    },
  },
});
