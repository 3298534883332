












import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import AccommodationInfoBoxMap from "./AccommodationInfoBoxMap.vue";

import { settingStore } from "@/store/modules/setting";
import { IDates } from "@/interfaces/common";

@Component({
  components: {
    "info-box-map": AccommodationInfoBoxMap,
  },
})
export default class AccommodationsMap extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  readonly accommodations!: [];

  @Prop({
    type: Object,
    default: () => ({
      Begin: null,
      End: null,
    }),
  })
  dates!: IDates;
  //   components: {
  //     "info-box-map": AccommodationInfoBoxMap,
  //   },
  //   props: {
  //     accommodations: {
  //       type: Array,
  //       default: () => [],
  //     },
  //   },
  google!: any;
  map: any;
  markerCluster!: MarkerClusterer;
  infowindow: any;
  coordinates = {
    Lat: 38.3651583,
    Lng: -0.8816106,
  };
  accommodationSelected = null;
  //   data: () => ({
  //     google: null,
  //     map: null,
  //     markerCluster: null,
  //     infowindow: null,
  //     coordinates: {
  //       Lat: 38.3651583,
  //       Lng: -0.8816106,
  //     },
  //     accommodationSelected: null,
  //   }),
  @Watch("accommodations")
  watchAccommodations(arr: any[]) {
    if (arr.length > 0) {
      this.coordinates = this.calculateAvgCoords(arr);
      this.addMarkers(arr);
    }
  }

  @Watch("coordinates")
  watchCoordinates(val: { Lat: any; Lng: any }) {
    this.map.setCenter({
      lat: val.Lat,
      lng: val.Lng,
    });
  }

  /**
   * Crea el cluster de marcadores
   */
  addMarkers(accommodations: any[]) {
    // Borramos todos los anteriores
    // this.markerCluster.clearMarkers();
    for (const acco of accommodations) {
      const marker = new this.google.maps.Marker({
        position: {
          lat: acco.Coordinate.Lat + (Math.random() - 0.9) / 1500,
          lng: acco.Coordinate.Lng + (Math.random() - 0.9) / 1500,
        },
        map: this.map,
        icon: "https://scriptvjs.i-rent.net/img/map-marker-green.png",
      });
      marker.addListener("click", () => {
        this.handleMarkerPopup(acco);
        this.infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });
      this.markerCluster.addMarker(marker);
    }
  }

  /**
   * Calcula el promedio de coordenadas para centrar el mapa
   */
  calculateAvgCoords(accommodations: any[]) {
    // Sacamos el promedio de las coordenadas para centrar el mapa
    const total = accommodations.reduce(
      (
        prev: { Coordinate: { Lat: any; Lng: any } },
        current: { Coordinate: { Lat: any; Lng: any } }
      ) => ({
        Coordinate: {
          Lat: prev.Coordinate.Lat + current.Coordinate.Lat,
          Lng: prev.Coordinate.Lng + current.Coordinate.Lng,
        },
      }),
      { Coordinate: { Lat: 0, Lng: 0 } }
    );
    return {
      Lat: total.Coordinate.Lat / accommodations.length,
      Lng: total.Coordinate.Lng / accommodations.length,
    };
  }

  /**
   * Captura el evento de popup del marcador del alojamiento
   */
  handleMarkerPopup(accommodation: null) {
    this.accommodationSelected = accommodation;
    this.infowindow.setContent((this.$refs["markerPopup"] as Vue).$el);
  }

  async mounted() {
    // Creamos el mapa
    const loader = new Loader({
      apiKey: settingStore.mapKey,
      version: "weekly",
      libraries: [],
    });
    this.google = await loader.load();
    this.map = new this.google.maps.Map(this.$refs.map, {
      center: {
        lat: this.coordinates.Lat,
        lng: this.coordinates.Lng,
      },
      zoom: 10,
      maxZoom: 17,
      streetViewControl: false,
      styles: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
    this.map.addListener("click", () => {
      if (this.infowindow) {
        this.infowindow.close();
      }
    });
    // Creamos el cluster de marcadores
    this.markerCluster = new MarkerClusterer({ map: this.map, markers: [] });
    // Inicializamos las coordenadas de los alojamientos
    if (this.accommodations.length > 0) {
      this.coordinates = this.calculateAvgCoords(this.accommodations);
      this.addMarkers(this.accommodations);
    }
    // Creamos la ventana de informacion de los marcadores
    this.infowindow = new this.google.maps.InfoWindow({
      content: (this.$refs["markerPopup"] as Vue).$el,
    });
  }
}
