
























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { irentAPI } from "@/services/api";

import { routerStore } from "@/store/modules/router";
import CustomModal from "@/components/CustomModal.vue";

@Component({
  components: {
    CustomModal,
  },
})
export default class ConfirmOption extends Vue {
  valid = false;
  confirmDialog = false;
  loading = false;
  OptionNumber = "";
  errorMsg = "";
  OptionNumberRules = [
    (v) => !!v || this.$t("listResults.optionbox.labelOptionRequired"),
    (v) =>
      /\d{4}-\d{5}/.test(v) ||
      this.$t("listResults.optionbox.labelOptionFormat"),
  ];
  TenantEmail = "";
  TenantEmailRules = [
    (v) => !!v || this.$t("listResults.optionbox.labelEmailRequired"),
    (v) =>
      /.+@.+\..+/.test(v) || this.$t("listResults.optionbox.labelEmailValid"),
  ];
  openConfirm() {
    this.confirmDialog = true;
  }
  closeDialog() {
    this.confirmDialog = false;
    this.OptionNumber = "";
    this.TenantEmail = "";
    this.errorMsg = "";
    this.$refs.form.resetValidation();
  }
  up() {
    this.errorMsg = "";
  }
  async searchOption() {
    this.loading = true;
    if (!this.$refs.form.validate()) return;

    const res = await irentAPI.post("/v1/Reservation/getOption", {
      OptionNumber: this.OptionNumber,
      TenantEmail: this.TenantEmail,
    });
    if (res.status != 200) {
      this.errorMsg = this.$t("listResults.optionbox.optionNotFound");
    }
    this.loading = false;
    this.goToOption(res.data);
  }

  goToOption(res) {
    routerStore.setRoute({
      name: "BookingProcess",
      props: {
        accommodationId: res.Option.Accommodation.Id,
        arrival: res.Option.Dates.Begin,
        departure: res.Option.Dates.End,
        guests: {
          GuestAdults: res.Option.Guests,
          GuestChildrens: 0,
          GuestInfants: 0,
        },
        tenant: res.Tenant,
        priceBreakDown: res.PriceBreakDown,
        option: res.Option,
      },
    });
  }
}
