import { render, staticRenderFns } from "./TermsAndConditions.vue?vue&type=template&id=5ce52732&"
import script from "./TermsAndConditions.vue?vue&type=script&lang=ts&"
export * from "./TermsAndConditions.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./TermsAndConditions.vue?vue&type=style&index=0&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCheckbox,VForm})
