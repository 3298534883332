


























































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { irentAPI } from "@/services/api";
import { authStore } from "@/store/modules/auth";
import { listStore } from "@/store/modules/list";

import {
  IAccommodationListItem,
  IAccommodationSearch,
  IAccommodationSearchResponse,
} from "@/interfaces/accommodation";
import { IDates, ILocalGuests, ILocalLocation } from "@/interfaces/common";

import ReservationDatesField from "@/components/ReservationDatesField.vue";
import GuestsField from "@/components/GuestsField.vue";
import AccommodationsMap from "@/components/listResults/AccommodationsMap.vue";

import VSelect from "@alfsnd/vue-bootstrap-select";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

import DateRangePicker from "vue2-daterange-picker";
import vueHeadful from "vue-headful";

import ConfirmOption from "@/components/ConfirmOption.vue";
import Discounts from "@/components/Discounts.vue";
type SortType =
  | "price_asc"
  | "price_desc"
  | "size_asc"
  | "size_desc"
  | "name_asc"
  | "name_desc";

@Component({
  components: {
    ReservationDatesField,
    GuestsField,
    AccommodationsMap,
    VSelect,
    DateRangePicker,
    VueTypeaheadBootstrap,
    vueHeadful,
    ConfirmOption,
    Discounts,
  },
})
export default class ListResults extends Vue {
  query = "";
  datesSelected = false;
  @Prop({
    type: Number,
    default: 14,
  })
  readonly accommodationsPerList!: number;
  @Prop({
    type: Array,
    default: () => [
      "price_asc",
      "price_desc",
      "size_asc",
      "size_desc",
      "name_asc",
      "name_desc",
    ],
  })
  readonly sortItems!: string[];
  sortOptions: { text: string; value: string }[] = [];

  @Prop({
    type: Array,
    default: () =>
      Array<number>(6)
        .fill(0)
        .map((_, i) => i + 1),
  })
  readonly bedroomsItems!: number[];
  @Prop({
    type: Array,
    default: () =>
      Array<number>(6)
        .fill(0)
        .map((_, i) => i + 1),
  })
  readonly bathroomsItems!: number[];
  @Prop({
    type: Array,
    default: () => [
      "GolfView",
      "ResortLocated",
      "SkiInSkiOut",
      "LakeView",
      "MountainView",
      "SeaView",
      "DistanceBeach",
      "DistanceSkiSlopes",
      "DistanceSeaRiverLake",
    ],
  })
  readonly locationItems!: string[];
  @Prop({
    type: Array,
    default: () => [
      "ChildrenWelcome",
      "Pets",
      "Pool",
      "OutdoorKitchen",
      "CribAvailable",
      "Fireplace",
      "FitnessArea",
      "Barbecue",
      "Jacuzzi",
      "Laundry",
      "DistanceTennis",
    ],
  })
  readonly amenitiesItems!: string[];
  @Prop({
    type: Array,
    default: () => [
      "HasDiscounts",
      "Internet",
      "TV",
      "Airaco",
      "Bedlinen",
      "Dishwasher",
      "Housekeeping",
      "Parking",
      "Wheelchair",
      "SmokingAllowed",
      "Garden",
    ],
  })
  readonly featuresItems!: string[];
  parentUrl: string | null = null;
  seeingFavorites = false;
  seeingDiscounts = false;
  distanceFilters: { [index: string]: number } = {
    DistanceSupermarket: 500,
    DistanceRestaurants: 500,
    DistanceBeach: 1000,
    DistanceStores: 500,
    DistanceTennis: 1500,
    DistanceGolf: 5000,
    DistanceHipico: 5000,
    DistanceAirport: 10000,
    DistanceSkiSlopes: 10000,
    DistanceSeaRiverLake: 5000,
  };
  get urlQueryDates() {
    let query = "";
    if (this.datesSelected) {
      query = `&arrival=${this.localDates.Begin}&departure=${this.localDates.End}`;
    }
    if (this.localGuests) {
      query += `&adults=${this.localGuests.GuestAdults}&children=${this.localGuests.GuestChildrens}&infants=${this.localGuests.GuestInfants}`;
    }
    return query;
  }
  get filtersCounters() {
    return this.accommodationsSearch?.reduce<{ [x: string]: number }>(
      (prev, curr) => {
        for (const filter in curr.Filters) {
          if (
            (typeof curr.Filters[filter] == "boolean" ||
              typeof curr.Filters[filter] == "number") &&
            curr.Filters[filter]
          ) {
            if (!prev[filter]) prev[filter] = 0;
            if (this.distanceFilters[filter]) {
              if (curr.Filters[filter] <= this.distanceFilters[filter])
                prev[filter]++;
            } else {
              prev[filter]++;
            }
          }
        }
        return prev;
      },
      {}
    );
  }
  get housesWithDiscounts() {
    return this.accommodationsSearch?.filter((obj) => obj.HasDiscounts).length;
  }
  /**
   * Cantidad de paginas segun los resultados
   */
  get listPages() {
    return (
      Math.trunc(this.accoIds.length / this.accommodationsPerList) +
      Math.sign(this.accoIds.length % this.accommodationsPerList)
    );
  }

  get locations() {
    return authStore.locations;
  }

  get totalLocalGuests() {
    const result = this.localGuests
      ? this.localGuests.GuestAdults +
        this.localGuests.GuestChildrens +
        this.localGuests.GuestInfants
      : 0;
    return result;
  }

  get accommodationTypes() {
    return authStore.accommodationTypes;
  }

  get selectedFilters() {
    return Object.keys(this.localFilters).filter((x) => this.localFilters[x]);
  }

  get favoritesAcco() {
    return listStore.favoritesAcco;
  }

  sortType: SortType = "price_asc";
  gridType = 1;
  listLoaded = false;
  listPage = 1;
  accoIds: number[] = [];
  accommodationsSearch: IAccommodationSearch[] | null = [];
  accommodations: IAccommodationListItem[] = [];
  localLocation: ILocalLocation | null = null;
  localAccoSearchResult: IAccommodationSearch | null = null;
  accomodationsNames: IAccommodationSearch[] = [];
  searchAccoName = "";
  localDates: IDates = {
    Begin: null,
    End: null,
  };
  localGuests: ILocalGuests | null = null;
  localAccommodationType: number[] | null = null;
  localBedrooms = 0;
  localBathrooms = 0;
  localPriceRange: [number, number] = [0, 0];
  localFilters: { [x: string]: boolean | number } = {};
  irentElementOffset = 0;

  accommodationTypesLocal: { Name: string; Type: number[] }[] = [];

  @Watch("accoIds")
  protected async watchAccommodationsIds(ids: number[]) {
    // Buscamos los ids de la pagina seleccionada
    this.listLoaded = false;
    const accommodations = await this._getAccommodationList(
      ids.slice(
        this.accommodationsPerList * (this.listPage - 1),
        this.accommodationsPerList * this.listPage
      ),
      {
        guests: this.totalLocalGuests,
        dates: this.localDates,
      }
    );
    this.accommodations = accommodations ? accommodations : [];
    this._sortAccommodations(this.accommodations, this.sortType);
    this.listLoaded = true;
  }

  @Watch("sortType")
  protected watchSortType() {
    // Ordenamos por el criterio seleccionado
    // let sortTypeSelected = sortType;
    this.listPage = 1;
    this._sortAccommodations(this.accommodationsSearch, this.sortType);
    let ids = this.accommodationsSearch?.map((acco) => acco.AccommodationId);
    if (ids) {
      this.accoIds = [...ids];
    }
    this.changePage();
  }
  protected mounted() {
    this.irentElementOffset = this.$common.getTop(
      document.querySelector("irent-script") as HTMLElement
    );
    let accoTypes = this.accommodationTypes.map((x) => x.Type);
    let appartaments = [65, 83, 84];
    let villasycasas = accoTypes.filter((x) => !appartaments.includes(x));
    this.accommodationTypesLocal = [
      {
        Name: this.$t(
          "listResults.gridList.accommodationsType.villasycasas"
        ).toString(),
        Type: villasycasas,
      },
      {
        Name: this.$t(
          "listResults.gridList.accommodationsType.apartments"
        ).toString(),
        Type: [65, 83, 84],
      },
    ];
    this.sortItems.forEach((item) => {
      this.sortOptions.push({
        text: this.$common.capitalize(
          this.$t(`listResults.navbar.sort_items.${item}`) as string
        ),
        value: item,
      });
    });

    //console.log("SM:" + this.$vuetify.breakpoint.sm);
    this.seeingFavorites = listStore.showingFavorites;

    // Inicializamos los filtros
    this.localFilters = this.locationItems.reduce<{
      [x: string]: boolean | number;
    }>((prev, curr) => {
      prev[curr] = false;
      return prev;
    }, {});
    this.listPage = listStore.pageNumber;
    this.parentUrl = authStore.parentUrl;
    this.setStoredSearch();
    this.search(false, true);
  }

  protected setStoredSearch() {
    this.localLocation = listStore.location;
    this.localDates = listStore.dates;
    this.localAccommodationType = listStore.accommodationType;
    this.localBedrooms = listStore.bedrooms ?? 0;
    this.localBathrooms = listStore.bathrooms ?? 0;
    this.localPriceRange = listStore.priceRage;
    this.localGuests = listStore.guests ?? {
      GuestAdults: 1,
      GuestChildrens: 0,
      GuestInfants: 0,
    };
    listStore.filters.forEach((filter) => {
      this.localFilters[filter] = true;
    });
  }

  /***
   * Busqueda de los alojamientos
   * @param {boolean} restartPage - true to set in first page
   * ***/
  protected async search(restartPage?: boolean, firstTime = false) {
    if (restartPage) {
      this.listPage = 1;
    }
    // Buscamos los ids de de los resultados
    if (!firstTime) {
      await this.scrollTo("results-container");
    }
    this.listLoaded = false;
    listStore.setPage({ pageNumber: this.listPage });
    // Buscamos los ids
    let searchOptions = {
      location: this.localLocation,
      dates: this.localDates,
      guests: this.localGuests,
      totalGuests: this.totalLocalGuests,
      typeOfAccommodation: this.localAccommodationType,
      bedrooms: this.localBedrooms,
      bathrooms: this.localBathrooms,
      priceRage: this.localPriceRange,
      filters: this.selectedFilters,
    };
    this.datesSelected =
      this.localDates.Begin != null && this.localDates.Begin != "";
    listStore.saveSearchOptions(searchOptions);
    this.accommodationsSearch = await this._getAccommodationsSearch(
      searchOptions
    );
    if (!this.seeingFavorites) {
      // Ordenamos por el criterio seleccionado
      this._sortAccommodations(this.accommodationsSearch, this.sortType);
      let ids = this.accommodationsSearch?.map((acco) => acco.AccommodationId);
      if (ids) {
        this.accoIds = [...ids];
      }
    } else {
      this.seeFavorites(true);
    }
    // Una vez finalizado, borramos el cancelador
    this.listLoaded = true;
  }

  protected async changePage() {
    await this.scrollTo("results-container");
    this.listLoaded = false;
    const accommodations = await this._getAccommodationList(
      this.accoIds.slice(
        this.accommodationsPerList * (this.listPage - 1),
        this.accommodationsPerList * this.listPage
      ),
      {
        guests: this.totalLocalGuests,
        dates: this.localDates,
      }
    );
    this.accommodations = accommodations ? accommodations : [];
    this._sortAccommodations(this.accommodations, this.sortType);
    this.listLoaded = true;
  }

  public async scrollTo(ref: string) {
    let toolbar = this.$el.querySelector("#toolbar");
    const element = this.$refs[ref] as HTMLDivElement;
    if (!element) return;
    const top = element.offsetTop - (toolbar as HTMLElement).scrollHeight;
    await this.$vuetify.goTo(this.irentElementOffset + top, {
      duration: 0,
      offset: 0,
      easing: "easeOutQuint",
    });
  }

  protected addOrRemoveFavoriteAcco(accoID: number) {
    return listStore.addOrRemoveFavoriteAcco(accoID);
  }

  private _sortAccommodations(
    ids: IAccommodationListItem[] | IAccommodationSearch[] | null,
    sortType: SortType
  ) {
    switch (sortType) {
      case "price_asc":
        ids?.sort((a: any, b: any) => a.SalePrice - b.SalePrice);
        break;
      case "price_desc":
        ids?.sort((a: any, b: any) => b.SalePrice - a.SalePrice);
        break;
      case "size_asc":
        ids?.sort((a: any, b: any) => a.BuildingSurface - b.BuildingSurface);
        break;
      case "size_desc":
        ids?.sort((a: any, b: any) => b.BuildingSurface - a.BuildingSurface);
        break;
      case "name_asc":
        ids?.sort((a: any, b: any) => a.Name.localeCompare(b.Name));
        break;
      case "name_desc":
        ids?.sort((a: any, b: any) => b.Name.localeCompare(a.Name));
        break;
    }
  }

  private async _getAccommodationsSearch(
    params: {
      location?: ILocalLocation | null;
      dates?: IDates;
      totalGuests?: number;
      typeOfAccommodation?: number[] | null;
      bedrooms?: number | null;
      bathrooms?: number | null;
      priceRage?: [number, number];
      filters?: string[];
    } = {}
  ) {
    const {
      location,
      dates,
      totalGuests,
      typeOfAccommodation,
      bedrooms,
      bathrooms,
      priceRage,
      filters,
    } = params;
    try {
      // Filtramos los filtros que esten solamente en true
      const filtersBody =
        filters?.reduce<{ [k: string]: boolean | number }>((prev, curr) => {
          if (this.distanceFilters[curr]) {
            prev[curr] = this.distanceFilters[curr]; //distancia maxima pata considerarlo cerca
          } else {
            prev[curr] = true;
          }
          return prev;
        }, {}) ?? {};
      const res = await irentAPI.post<IAccommodationSearchResponse>(
        "/v1/Accommodation/Search",
        {
          Guests: totalGuests ?? 0,
          Location: location ?? {},
          Currency: authStore.currency,
          Dates: dates?.Begin && dates?.End ? dates : undefined,
          Filters: {
            PriceRange: priceRage?.[1]
              ? {
                  Begin: priceRage[0],
                  End: priceRage[1],
                }
              : {
                  Begin: null,
                  End: null,
                },
            TypeOfAccommodation: typeOfAccommodation
              ? typeOfAccommodation
              : undefined,
            Bedrooms: bedrooms ? bedrooms : undefined,
            Bathrooms: bathrooms ? bathrooms : undefined,
            ...filtersBody,
          },
          WebsiteId: authStore.websiteId,
          Winter: authStore.winter,
          Sales: authStore.sales,
        }
      );
      return res.data.Accommodations;
    } catch (err) {
      console.warn("[POST][ACCOMMODATION_SEARCH]: ", err);
      return null;
    }
  }

  private async _getAccommodationList(
    ids: number[],
    params?: { guests?: number; dates: IDates }
  ) {
    try {
      const res = await irentAPI.post<IAccommodationListItem[]>(
        "/v1/Accommodation/List",
        {
          AccommodationsIds: ids,
          Currency: authStore.currency,
          Dates:
            params?.dates.Begin && params?.dates.End ? params.dates : undefined,
          Guests: params?.guests ?? 0,
          Language: this.$i18n.locale,
          WebsiteId: authStore.websiteId,
        }
      );
      return res.data;
    } catch (err) {
      console.warn("[POST][ACCOMMODATION_LIST]: ", err);
      return null;
    }
  }
  protected goToDetail(accommodation: { AccommodationId: any }) {
    listStore.setScrollTop({ top: window.scrollY });
    this.$common.to("SalesAccoDetail", {
      accommodationId: accommodation.AccommodationId,
    });
  }
  showingMap = false;
  showMap() {
    if (this.showingMap) {
      this.showingMap = false;
    } else {
      this.showingMap = true;
      setTimeout(() => {
        let mapSection = this.$el.querySelector("#map-section");
        let toolbar = this.$el.querySelector("#toolbar");
        let top =
          (mapSection as HTMLElement).offsetTop -
          (toolbar as HTMLElement).scrollHeight -
          56;
        window.scrollTo(0, this.irentElementOffset + top);
      }, 500);
    }
  }

  seeFavorites(see: boolean) {
    this.seeingFavorites = see;
    listStore.showFavorites(this.seeingFavorites);
    if (this.seeingFavorites) {
      this.accoIds = [...listStore.favoritesAcco];
      this.accommodationsSearch =
        this.accommodationsSearch?.filter((acco) =>
          this.favoritesAcco.includes(acco.AccommodationId)
        ) ?? null;
    } else {
      this.search(true);
    }
  }

  // showDiscounts(see: boolean) {
  //   this.seeingDiscounts = see;
  //   if (this.seeingDiscounts) {
  //     this.listPage = 1;
  //     this.accommodationsSearch =
  //       this.accommodationsSearch?.filter((acco) => acco.HasDiscounts) ?? null;
  //     this.accoIds =
  //       this.accommodationsSearch?.map((x) => x.AccommodationId) ?? [];
  //   } else {
  //     this.search(true);
  //   }
  // }

  // bedroomPlaceHolder() {
  //   let lab = this.$tc(
  //     "listResults.sidebar.bedroom.bedroom",
  //     this.localBedrooms
  //   );
  //   return (
  //     (this.localBedrooms != 0 ? this.localBedrooms + " " : "") +
  //     this.$common.capitalize(lab)
  //   );
  // }
  // bathroomPlaceHolder() {
  //   let lab = this.$tc(
  //     "listResults.sidebar.bathroom.bathroom",
  //     this.localBathrooms
  //   );

  //   return (
  //     (this.localBathrooms != 0 ? this.localBathrooms + " " : "") +
  //     this.$common.capitalize(lab)
  //   );
  // }
  // accoPrice(price: number) {
  //   if (!this.datesSelected && authStore.winter) {
  //     return (price / 7) * 30;
  //   } else {
  //     return price;
  //   }
  // }
  isLoadingAccos = false;
  // async searchName() {
  //   this.localAccoSearchResult = null;
  //   if (this.accomodationsNames.length > 0) return;
  //   if (this.isLoadingAccos) return;
  //   this.isLoadingAccos = true;
  //   const res = await irentAPI.post<IAccommodationSearchResponse>(
  //     "/v1/Accommodation/Search",
  //     {
  //       Location: {},
  //       Guests: 0,
  //       Currency: authStore.currency,
  //       WebsiteId: authStore.websiteId,
  //     }
  //   );
  //   this.accomodationsNames = res.data.Accommodations;
  //   this.isLoadingAccos = false;
  // }
  get websiteName() {
    return authStore ? authStore?.administrator?.Agent.Name : "";
  }
  get pageTitle() {
    return this.accommodationsSearch
      ? `
        ${this.accommodationsSearch.length} ${this.$t("common.holidayHomes")} ${
          this.localLocation ? "in " + this.query : this.$t("common.forRent")
        } | ${this.websiteName} ${this.listPage}`
      : this.$t("common.search");
  }

  get metaDescription() {
    return this.accommodationsSearch
      ? ` ${this.query} ${this.websiteName} - ${
          this.accommodationsSearch.length
        } ${this.$t("common.holidayHomes")} ${
          this.query ? this.$t("common.in") + this.query : ""
        } - ${this.listPage}`
      : this.websiteName;
  }
}
