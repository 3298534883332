





























































































































































































































































import Vue from "vue";
import { validationMixin } from "vuelidate";
import { Validate } from "vuelidate-property-decorators";
import { Component, Prop } from "vue-property-decorator";
import { email, required, maxLength } from "vuelidate/lib/validators";

import { irentAPI } from "@/services/api";
import { authStore } from "@/store/modules/auth";

export interface IContactPersonalDetail {
  Treatment: string | null;
  Address: {
    StreetName: string;
    Postcode: string;
    City: {
      Name: string;
    };
    Province: {
      Name: string;
    };
    Country: string;
  };
  Telephone1: string;
  Mobile1: string;
  Mail1: string;
  Language: string;
  Comment?: string;
  Company?: string;
  Name?: string;
  Surname?: string;
}

@Component({
  mixins: [validationMixin],

  validations: {
    item: {
      surname: {
        required,
      },
      address: {
        required,
      },
      email: {
        required,
        email,
      },
      postalCode: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      mobile: {
        required,
        maxLength,
      },
    },
  },
})
export default class PersonalDetail extends Vue {
  @Prop({
    type: Object,
  })
  tenantData: any;

  item = {
    surname: "",
    address: "",
    postalCode: "",
    country: null,
    city: null,
    mobile: "",
    email: "",
  };

  title: null | string = null;
  titles = new Array<{ value: string; text: string }>();

  firstName = "";
  city = "";
  province = "";
  phone = "";
  comment = "";
  countries = [];

  protected async mounted() {
    this.titles = [
      {
        value: "mr",
        text: this.$t("reservation.mr").toString(),
      },
      {
        value: "mrs",
        text: this.$t("reservation.mrs").toString(),
      },
      {
        value: "family",
        text: this.$t("reservation.family").toString(),
      },
      {
        value: "company",
        text: this.$t("reservation.company").toString(),
      },
    ];
    let response = await irentAPI.get(
      `/v1/Website/CountriesList?Language=${authStore.language}`
    );
    this.countries = response.data.map((x) => {
      return { Id: x.country_id, Name: x.country_name };
    });
    if (this.tenantData) {
      this.item.surname = this.tenantData.Surname;
      this.firstName = this.tenantData.Name;
      this.item.address = this.tenantData.Address;
      this.item.postalCode = this.tenantData.PostalCode;
      this.item.country = this.countries.find(
        (x) => x.Name == this.tenantData.Country
      );
      this.item.mobile = this.tenantData.Mobile;
      this.item.email = this.tenantData.Mail;
      this.item.city = this.tenantData.City;
      this.province = this.tenantData.Province;
      this.phone = this.tenantData.Telephone;
      this.title = this.titles[this.tenantData.Treatment].value;
    }
  }

  validateState(item: any) {
    const { $dirty, $error } = item;
    return $dirty ? !$error : null;
  }

  save() {
    this.$v.item.$touch();
    if (this.$v.item.$anyError) return false;

    const result: IContactPersonalDetail = {
      Treatment: this.title,
      Address: {
        StreetName: this.item.address,
        Postcode: this.item.postalCode,
        City: {
          Name: this.item.city,
        },
        Province: {
          Name: this.province,
        },
        Country: this.item.country,
      },
      Telephone1: this.phone,
      Mobile1: this.item.mobile,
      Mail1: this.item.email,
      Language: this.$common.toLang(),
      Comment: this.comment,
    };
    if (this.title == "company") {
      result.Company = this.item.surname;
    } else {
      if (this.title != "family") {
        result.Name = this.firstName;
      }
      result.Surname = this.item.surname;
    }
    return result;
  }
}
