import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "router",
})
export class RouterStore extends VuexModule {
  routeName = "";
  routeProps: { [x: string]: any } = {};

  @Mutation
  setRoute(payload: { name: string; props?: { [x: string]: any } }) {
    this.routeName = payload.name;
    this.routeProps = payload.props ?? {};
  }
}

export const routerStore = getModule(RouterStore);
