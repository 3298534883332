









































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import CustomModal from "@/components/CustomModal.vue";

@Component({
  components: {
    CustomModal,
  },
})
export default class TermsAndConditions extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly value!: boolean;

  dialog = false;

  openConditions() {
    this.dialog = true;
  }
}
